.element__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: black;
  margin: 21px 0 0 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}
