.popup__input {
  width: 358px;
  height: 27px;
  margin: 30px 0 0 36px;
  border: none;
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .popup__input {
    margin: 30px 22px 0;
    width: 238px;
  }
}
