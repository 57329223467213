.profile__avatar-container {
  position: relative;
  z-index: 2;
  height: 120px;
}

.profile__avatar-container::before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  z-index: 3;
  background-image: url(./pencilSVG.svg);
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}

.profile__avatar-container:hover::before {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
}
