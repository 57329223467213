.footer__copyright {
  color: rgba(84, 84, 84, 0.79);
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
@media screen and (max-width: 767px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}
