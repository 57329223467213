.auth__title {
  color: white;
  margin: 60px 0 20px;
  padding: 0;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
