.profile {
  max-width: 880px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .profile {
    margin: 42px 19px 0;
    flex-direction: column;
  }
}
