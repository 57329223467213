.popup__image-description {
  position: absolute;
  bottom: -20px;
  left: 0px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
