.popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
  opacity: 0.5;
  background: black;
  transition: opacity 0.3s;
}
