.profile__description {
  align-self: flex-start;
  grid-area: description;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .profile__description {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}
