.auth__button {
  margin: 216px 0 0;
  width: 358px;
  height: 50px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 767px) {
  .auth__button {
    width: 238px;
    height: 46px;
    margin: 45px 22px 0;
    font-size: 14px;
    line-height: 17px;
  }
}

.auth__button:hover {
  opacity: 0.85;
}
