.popup__input-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
  position: absolute;
  display: block;
  margin: 5px 36px 0;
}

@media screen and (max-width: 767px) {
  .popup__input-error {
    margin: 5px 22px 0;
  }
}
