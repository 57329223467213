.profile__text {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 18px;
  row-gap: 16px;
  grid-template-areas:
    "name edit-button"
    "description .";
  margin: auto 0;
}
@media screen and (max-width: 1023px) {
  .profile__text {
    column-gap: 10px;
    row-gap: 4px;
    margin-top: 26px;
    margin-left: 20px;
  }
}
