.header__email {
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 5px 0 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .header__email {
    font-size: 10px;
  }
}
