.popup__button-confirm {
  display: block;
  width: 358px;
  height: 50px;
  margin: 48px 0 0 36px;
  background-color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  border: none;
}
@media screen and (max-width: 767px) {
  .popup__button-confirm {
    width: 238px;
    height: 46px;
    margin: 45px 22px 0;
    font-size: 14px;
    line-height: 17px;
  }
}
.popup__button-confirm:hover {
  opacity: 0.8;
}
