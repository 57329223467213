.header {
  max-width: 880px;
  margin: 0 auto;
  padding-top: 45px;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1023px) {
  .header {
    padding-top: 28px;
  }
}
