.profile__avatar {
  border-radius: 100%;
  max-width: 120px;
  height: 120px;
  margin-right: 30px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1023px) {
  .profile__avatar {
    margin: 0 auto;
  }
}
