.popup__container {
  background-color: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 430px;
  height: 330px;
  position: fixed;
  left: calc(50% - (430px / 2));
  top: calc(50vh - (330px / 2));
  z-index: 5;
}
@media screen and (max-width: 767px) {
  .popup__container {
    width: 282px;
    height: 322px;
    left: calc(50vw - (282px / 2));
    top: calc(50vh - (322px / 2));
  }
}
