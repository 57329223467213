.auth__input {
  width: 358px;
  height: 27px;
  margin: 30px 0 0;
  border: none;
  border-bottom: 1px rgba(204, 204, 204, 1) solid;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0;
  background-color: black;
  color: white;
}

@media screen and (max-width: 767px) {
  .auth__input {
    margin: 30px 22px 0;
    width: 238px;
  }
}
