.footer {
  max-width: 880px;
  margin: 66px auto 0;
  padding-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .footer {
    margin: 48px 19px 0;
    padding-bottom: 36px;
  }
}
