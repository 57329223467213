.profile__name {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
  grid-area: name;
  max-width: 500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .profile__name {
    font-size: 27px;
    line-height: 33px;
    text-align: center;
  }
}
