.popup {
  z-index: 2;
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  top: 0;
  left: 0;
}
