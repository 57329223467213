.elements {
  max-width: 880px;
  margin: 50px auto 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  row-gap: 20px;
  column-gap: 17px;
}
@media screen and (max-width: 880px) {
  .elements {
    max-width: 582px;
    margin-top: 36px;
  }
}
@media screen and (max-width: 767px) {
  .elements {
    max-width: 282px;
  }
}
