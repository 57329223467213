.popup__button-close {
  position: absolute;
  top: -40px;
  right: -40px;
  margin: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  background-image: url(./Close-Icon.svg);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  background-position: center;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .popup__button-close {
    width: 20px;
    height: 20px;
    right: 0;
    top: -36px;
    background-image: url(./Close-Icon_320px.svg);
  }
}
.popup__button-close:hover {
  opacity: 0.6;
}
